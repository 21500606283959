.owl-carousel .nav-btn {
    position: absolute;
    width: 80px;
    cursor: pointer;
    top: 30%;
}

.owl-carousel .prev-slide {
    content: url('/storage/images/assets/navPrev.png');
    left: -33px;
}

.owl-carousel .next-slide {
    content: url('/storage/images/assets/navNext.png');
    right: -33px;
}

.owl-carousel .prev-slide-talent {
    content: url('/storage/images/assets/navPrev.png');
    left: 10px;
}

.owl-carousel .next-slide-talent {
    content: url('/storage/images/assets/navNext.png');
    right: 10px;
}
