// Fold, Iphone 4
@media (max-width: 359.98px) {

    .text-landing,
    .text-landing-outline {
        font-size: 28px !important;
    }

    .img-hide {
        display: none;
    }

    .important-section {
        background-position: -9rem 26rem,
            center center !important;

            .text-landing-orange{
                font-size: 22px;
            }
    }

    .benefit-section {
        background-position: -15rem top, center center !important;
        padding: 6rem 0 0 0 !important;
    }

    
    .class-section {
        padding: 4rem 0 2rem 0 !important;
    }

    .talent-section{
        padding: 5rem 0 0 0;
        background-size: 500px, 300px, 100%;
        background-position: -5rem -3rem, 5rem 25rem, center center;

            #img-talent{
                height: 200px;
            }
    }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

    .landing {
        background-position: center left;
        min-height: 100vh;
        height: 100vh;
    }

    .text-landing,
    .text-landing-outline {
        font-size: 36px;
    }

    .fluent-section{
        margin-top: -50px;
    }

    .ilustrasi img{
        opacity: 0%;
        width: 0px;
        height: 0px;
        display: none;
    }

    .kotak-ijo-muda{
    margin-right: 0px;
    margin-left: 0px;

    }

    .rounded-circle{
        width: 30px;

        .img{
            width: 15px;
        }
    }

    .heading-section{
        font-size: 12px;
    }
    .text-sub-title{
        font-size: 20px;
    }
    
    .card-reasons{
        margin-left: 15px;
        margin-right: 15px;
    }

    .important-info-img{
        margin-top: 10px;
        padding-left: 100px;
        padding-right: 100px;
    }
    .img-hide {
        display: none;
    }

    .owl-carousel {
        padding-left: 10px;
        padding-right: 10px;
    }

    .owl-carousel .nav-btn {
        width: 40px !important;
        top: 80px;
    }

    .owl-carousel .prev-slide {
        left: -12px !important;
    }

    .owl-carousel .next-slide {
        right: -12px !important;
    }

    .about-section {
        background-position: -35rem -1rem, 10rem 15rem, center center !important;
        padding: 15rem 0 0 0 !important;
        padding-top: 150px;

        .gambar-about{
            height: 0%;
            width: 0%;
            display: none;
        }

        .judul{
            font-size: 36px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .landing{
        padding: 10rem 0 0 0;
    }

    .heading-section{
        font-size: 12px;
    }
    .text-sub-title{
        font-size: 28px;
    }
    .important-section {
        padding: 5rem 0 0 0 !important;
        background-size: 450px, 100%;
        background-position: -9rem 24rem, center center;
        margin-left: 15px;
        margin-right: 15px;

        .important-info-img{
            display: none;
            opacity: 0%;
            height: 0%;
            width: 0%;
        }

    .text-landing-orange{
            font-size: 24px;
        }
    }

    .benefit-section {
        background-position: -13rem top, center center;
        padding: 5rem 0 0 0;

        h4{
            font-size: 20px;
        }
    }

    .class-section {
        padding: 5rem 0 0 0;
    }

    .quiz-section {
        padding: 0rem 0 3rem 0;
        background-size: 1000px, 100%;
    }

    .event-section {
        background-position: 80% 200px, center center;
        padding: 5rem 0 0 0;
    }

    .bom-section {
        padding: 5rem 0 0 0;
        margin-left: 15px;
        margin-right: 15px;
    }

    .talent-section {
        padding: 5rem 0 0 0;
        background-size: 500px, 300px, 100%;
        background-position: -5rem -3rem, 5rem 25rem, center center;
        margin-left: 15px;
        margin-right: 15px;

            #img-talent{
                height: 260px;
            }
    }

    .achievement-section {
        padding: 5rem 0;
    }

    .video-section,
    .partnership-section {
        padding: 5rem 0 0 0;
    }

    .powered-section {
        padding: 5rem 0 5rem 0;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

    .heading-section{
        font-size: 14px;
    }
    .landing {
        background-position: center left;
        min-height: 120vh !important;
        height: 120vh !important;
        border-radius: 0 0 80px 0;

        .text-landing,
    .text-landing-orange {
        font-size: 50px;
    }
    }

    .img-hide {
        display: none;
    }

    .text-sub-title{
        font-size: 28px;
    }

    .owl-carousel .nav-btn {
        width: 60px;
        top: 125px !important;
    }

    .about-section {
        background-position: -22rem -3rem, right bottom, center center !important;
        padding: 8rem 0 0 0;

        .gambar-about{
            height: 0%;
            width: 0%;
            display: none;
        }
    }

    .important-section {
        padding: 6rem 0 0 0;
        background-size: 450px, 100%;
        background-position: -10rem bottom, center center;

        .text-landing-orange{
            font-size: 30px;
        }

        .important-info-img{
            display: none;
            opacity: 0%;
            height: 0%;
            width: 0%;
        }
        
    }

    .benefit-section {
        background-position: -7rem top, center center;
        padding: 6rem 0 0 0;
        
        #reason-img {
            opacity: 100%;
        }

        #reasons-left{
            width: 450px;
        }

        #reasons-right{
            width: 450px;
        }

        h4{
            font-size: 20px;
        }
    }

    .class-section {
        padding: 6rem 0 0 0;
    }

    .quiz-section {
        padding: 0rem 0 3rem 0;
        background-size: 1500px, 100%;
    }

    .event-section {
        background-position: 80% 200px, center center;
        padding: 6rem 0 0 0;
    }

    .bom-section {
        padding: 6rem 0 0 0;
    }

    .talent-section {
        padding: 6rem 0 0 0;
        background-size: 800px, 400px, 100%;
        background-position: -5rem -5rem, right bottom, center center;
    }

    .achievement-section {
        padding: 6rem 0 0 0;
    }

    .video-section,
    .partnership-section {
        padding: 6rem 0 0 0;
    }

    .powered-section {
        padding: 6rem 0 6rem 0;

        .img{
            width: 50%;
        }
    }

    
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

    .landing {
        min-height: 30vh !important;
        height: 70vh !important;
        border-radius: 0 0 80px 0;
        background-size: 800px;
        width: 100%;
        padding-left: 1px;
        background-position: top center;
        padding-top: 200px;
        background-image: none;
        h1{
            text-align: center;
            margin-left: -100px;
        }
        h2 {
            text-align: center;
        }
    }

    .text-sub-title{
        font-size: 30px;
    }

    

    .text-landing,
    .text-landing-orange {
        font-size: 60px;
    }

    .img-hide {
        display: none;
    }

    .about-section {
        background-position: -26rem -2rem, right bottom, center center;
        padding: 15rem 0 0 0;

        .gambar-about{
            height: 0%;
            width: 0%;
            display: none;
        }
    }

    .important-section {
        padding: 8rem 0 0 0;
        background-position: left 10rem, center center;

        .text-landing-orange{
            font-size: 30px;
        }
    }

    .benefit-section {
        padding: 8rem 0 0 0;
        background-size: 700px, 100%;
        background-position: -9rem top, center center;
    }

    .quiz-section {
        padding: 2rem 0;
    }

    .event-section {
        padding: 8rem 0 0 0;
    }

    .bom-section {
        padding: 8rem 0 0 0;
    }

    .talent-section {
        padding: 8rem 0 0 0;
        background-size: 600px, 400px, 100%;
        background-position: -5rem -3rem, right 25rem, center center;

        .card-box{
            padding: 2px;
        }
    }

    .partnership-section{
        padding: 8rem 0 0 0;
    }

    .video-section{
        padding: 8rem 0 0 0;
    }

    .powered-section{
        padding: 8rem 0 8rem 0;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

    .landing {
        min-height: 80vh;
        height: 80vh;
        background-position: center center;
    }


    .text-landing,
    .text-landing-outline {
        font-size: 36px;
    }

    .text-sub-title{
        font-size: 28px;
    }
    
    .important-info-img{
        padding-left: auto;
        padding-right: auto;
    }

    .about-section {
        background-position: -30rem -1rem, right 15rem, center center;

        .gambar-about{
            height: 0%;
            width: 0%;
            display: none;
        }
    }

    .bom-section {
        padding: 10rem 0 0 0;

        h1{
            font-size: 30px;
        }
    }

    // .fluent-section{
    //     margin-left: 500px;
    //     margin-right: 200px;
    // }

    .talent-section {
        background-position: -10rem -7rem, 35rem 25rem, center center;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1299.98px) {}