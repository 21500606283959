//Datatables
.page-item.active .page-link {
    color: #fff;
    background-color: #1DB9A1;
    border-color: #1DB9A1;
}

.page-link {
    color: #1DB9A1;
}

.page-link:hover {
    color: #1DB9A1;
}

.page-link:focus {
    box-shadow: none;
}

.dataTables_length {
    text-align: left !important;
}

.dataTables_filter {
    @include media-breakpoint-down(sm) {
        text-align: left !important;
    }
}

.dataTables_info {
    padding-top: 0 !important;
    text-align: left !important;
}

table.dataTable.dtr-inline.collapsed.table-sm>tbody>tr>td:first-child::before,
table.dataTable.dtr-inline.collapsed.table-sm>tbody>tr>th:first-child::before {
    top: 50% !important;
}

div.dataTables_wrapper div.dataTables_paginate ul.pagination {
    @include media-breakpoint-down(sm) {
        justify-content: loadert !important;
    }
}
