* {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    scroll-behavior: smooth;
}

.text-teal {
    color: $teal;
}

.text-orange {
    color: $orange;
}

.bg-teal {
    background-color: $teal;
}

.bg-orange {
    background-color: $orange;
}

//ini tambahan
.btn-teal-landing {
    background: $teal;
    color: white;
    width: fit-content(20em);
    border-radius: 50px;
    padding: 50px;
    box-shadow: 0px 5px 0 rgb(0, 103, 103);
}

.btn-teal-landing:hover {
    background-color: #326A5E;
    color: white;
    width: fit-content(20em);
    border-radius: 50px;
    padding: 50px;
    box-shadow: 0px 5px 0 rgb(0, 103, 103);
}


.box-teal-outline {
    background: teal;
    border-radius: 5px 50px;
    box-shadow: 5px 50px;
    z-index: -1;
    bottom: 50%;
}

.btn-orange {
    background: $orange;
    color: white;
    border-radius: 50px;
}

.border-teal {
    border: 2px solid $teal;
}

.rounded-20 {
    border-radius: 20px;
}

.rounded-30 {
    border-radius: 30px;
}

.rounded-bottom-20 {
    border-radius: 0 0 20px 20px;
}

.section-line {
    background-color: $orange;
    width: 80px;
    height: 3px;
    margin-bottom: 1rem;
}

// Navbar (GW UBAHHHHHH)

.navbar {
    background-color: white;
    box-shadow: 0px 1px 7px gray;
}

.nav-button {
    background-color: transparent;
    color: #6D6D6D;
    padding: 0.3rem 1rem;
    border-radius: 50px;
}

.nav-button:hover {
    background-color: #1db9a1;
    color: white;
}

.nav-item{
    font-size: 18px;
}

.tombol-1{
    background-color: #1FBAA2;
    border-radius: 20px;
}

.tombol-2{
    background-color: #3A7E6F;
    border-radius: 20px;
}

.btn-teal {
    background: $teal;
    color: white;
    border-radius: 50px;

}

.nav-item,
#nav-text {
    display: inline-block;
    position: relative;
}

#nav-text:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background: #3A7F6E;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

#nav-text:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.nav-item {
    font-size: 18px;
}

.tombol-1 {
    background-color: #1FBAA2;
    border-radius: 20px;
}

.tombol-2 {
    background-color: #3A7E6F;
    border-radius: 20px;
}

// Footer  (GW UBAHHHHHHHHHH)
.footer {
    position: relative;
    color: $white;
    padding: 4rem 0;
    background-color: #141d2a;
    .container {
        position: relative;
    }
    h3 {
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 0;
        line-height: 1.5;
    }

    .links {
        -webkit-columns: 2;
        columns: 2;
        li {
            margin-bottom: 10px;
            line-height: 1.5;
            display: block;
            a {
                transition: .5s ease;
                color: #666873;
                &:hover {
                    color: $teal;
                }
            }
        }
    }

    .social {
        li {
            display: inline-block;
            position: relative;
            a {
                position: relative;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: inline-block;
                margin-right: .25rem;
                padding: 0;
                background-color: $teal;
                color: #fff;
                > span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
    .copyright {
        color: #666873;
    }

    .cta {
        box-shadow: -20px -20px 0 0 rgba($dark, .2);
        padding: 20px;
        background-color: $teal;
        top: -100px;
        position: relative;
        h2, h3 {
            line-height: 1.5;
        }
        h3 {
            font-size: 20px;
        }
    }
}

.fab {
    transform: scale(1.4);
}

// .social-media ul li a:hover {
//     border-color: #868686;
//     background-color: #868686;
// }

// .social-media ul li a:hover i {
//     color: #1db9a1;
// }

.btn-footer {
    padding: 0.3rem 3rem;
    background-color: transparent;
    border: 2px solid #1db9a1;
    color: white;
}

.btn-footer:hover {
    padding: 0.3rem 3rem;
    background-color: #1db9a1;
    color: white;
}

#talent,
#partner {
    transition: all 200ms ease-in-out;
}

#talent:hover,
#partner:hover {
    transform: scale(1.02);
}

//ini tambahan
.card-reasons, .card-achievments {
    // width: fit-content;
    padding: 3px;
    box-shadow: 0px 5px 0 #1FBAA2;
    background-color: #BCEAE3;
    color: black;
    border-radius: 17.63px;
    transition: all 200ms ease-in-out;
    margin-bottom: 10px;
}

.important-section-img{
    padding-left: 50px;
    padding-right: 50px;
}

.h4{
    font-size: 48px;
}

.card-reasons:hover {
    transform: scale(1.05);
}

.card-zoom {
    transition: 0.2s ease all;
}

.dewaweb img{
    transition: 0.3s;
    filter: grayscale(100%);
}

.dewaweb img:hover{
    filter: grayscale(0%) !important;
}

.heading-section{
    font-size: 18px;
}

.text-sub-title{
    font-size: 40px;
    line-height: 1.4;
    font-weight: 700;
}
.card-zoom:hover {
    transform: scale(1.05);
    background-color: #c0691d;
}

.card-body-hover {
    transition: all 200ms ease-in-out;
}

.card-body-hover:hover {
    background: linear-gradient(90deg, rgba(244, 187, 131, 0.5) 0%, rgba(154, 222, 209, 0.5) 100%);
    border-radius: 50px;
    padding: 5px 0px 5px 0px;
}

.section-title {
    font-weight: 900;
    font-style: italic;
    text-transform: uppercase;
}

h1 {
    font-weight: 900;
    font-style: italic;
    text-transform: uppercase;
}

//ada yg diedit
.landing {
    min-height: 110vh;
    height: 110vh;
    background-size: cover;
    background-position: bottom center;
    background-image: url("/storage/images/assets/home-bg.svg");
    // display:flex;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 250px;
    h1{
        text-align: center;
        justify-content: center;
    }
    h2{
        text-align: center;
        justify-content: center;
    }
}

.text-landing {
    font-size: 50px;
    font-style: italic;
    font-weight: 900;
    color: $teal;
    top: -10%;
}

//ada yang diedit
.text-landing-orange {
    font-size: 50px;
    font-style: italic;
    font-weight: 900;
    color: #E78B3C;
    text-shadow: -2px 2px #F4BB83;
}


.about-section {
    // background-image: url("/storage/images/assets/about-bg.svg");
    // min-height: 250vh;
    // height: 250vh;
    background-size: cover;
    // display:flex;
    background-repeat: no-repeat;
    width: 100%;

}

.ilustrasi img {
    transform: scale(1.3);
}

// GW UBAHHHHHHHH
.kotak-ijo-tua {
    background-color: #3A7E6F;
    color: white;
    border-radius: 17.63px;
    transition: all 200ms ease-in-out;
}


.kotak-ijo-tua:hover {
    background-color: #154138;
}

// GW UBAHHHHHHHHH
.kotak-ijo-muda {
    background-color: #BCEAE3;
    color: black;
    border-radius: 19.63px;
    box-shadow: 0px 5px 0 #1FBAA2;
    transition: all 200ms ease-in-out;
}

.kotak-ijo-muda:hover {
    transform: scale(1.05);
}


.important-section {
    padding: 10rem 0 0 0;
    // background: url('/storage/images/assets/important-teal-circle.png') no-repeat,
    //     #45DBCB;
    background-size: 500px, 100%;
    background-position: left 5rem, center center;

    .text-landing-orange{
        font-size: 48px;
    }
}

.benefit-section {
    padding: 10rem 0 0 0;
    // background: url('/storage/images/assets/benefit-orange-circle.webp') no-repeat,
    //     linear-gradient(to bottom, #45DBCB, #f8fafc);
    background-size: 600px, 100%;
    background-position: right center, center center;

    h4 {
        font-size: 20px;
    }

}



.class-section {
    padding: 10rem 0 0 0;
}

.quiz-section {
    padding: 2rem 0 6rem 0;
    // background: url('/storage/images/assets/event-orange-div.png') no-repeat,
    //     #f8fafc;
    background-size: 100%, 100%;
    background-position: center bottom,
        center center;
}

.event-section {
    padding: 10rem 0 0 0;
    // background: url('/storage/images/assets/bom-teal-obj.png') no-repeat, #F5831F;
    background-size: 150%,
        100%;
    background-position: 80% 250px,
        center center;
}

.bom-section {
    padding: 10rem 0 0 0;
}

.talent-section {
    padding: 10rem 0 0 0;
    // background: url('/storage/images/assets/talent-teal-circle.png') no-repeat,
    //     url('/storage/images/assets/talent-teal-circle2.png') no-repeat,
    //     linear-gradient(to bottom, #24C2A9, #45DBCB);
    background-size: 900px, 600px, 100%;
    background-position: -3rem -7rem, right 30rem, center center;
}

// .bg-green-to-white {
//     // background: linear-gradient(to bottom, #45DBCB, #f8fafc);
// }

.achievement{
    padding: 10rem 0 0 0;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background: linear-gradient(89.84deg, #81D8CA 20.99%, rgba(149, 222, 211, 0.66451) 63.15%, rgba(163, 226, 217, 0.421554) 104.62%, rgba(188, 234, 227, 0) 104.63%);
    border-color: #dee2e6 #dee2e6 #f8fafc;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    color: black;
}

.fluent-section{
    margin-left: auto;
    margin-right: auto;
}

.video-section,
.partnership-section {
    padding: 10rem 0 0 0;
}

.powered-section {
    padding: 10rem 0 10rem 0;
}

// GW UBAHHHHHHH
.bodi-kartu-partner img {
    width: 220px;
    height: 170px;
    margin-right: 20px;
}

// GW UBAHHHHHHHH
.deskripsi-partner h3 {
    justify-content: start;
}

// GW UBAHHHHHHHH
.deskripsi-partner-kotak {
    margin-right: 16px;
}

// GW UBAHHHHHHHHH
.bodi-kartu-partner {
    display: flex;
    justify-content: space-evenly;
}

@media screen and (max-width:992px) {
    .kartu-luar-partner {
        width: 400px;
        height: 700px;
    }

    .bodi-kartu-partner img {
        width: 250px;
        margin-right: 0px;
    }

    .bodi-kartu-partner {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .deskripsi-partner h3 {
        text-align: center;
    }

    .deskripsi-partner-kotak {
        margin-right: 0;
        // margin-bottom: 20px;
    }

    .deskripsi-promo {
        margin-top: -50px;
    }
}

.binus-english-club {
    width: 24rem;
    text-shadow: -2px 2px #F4BB83;
}

@media screen and (max-width: 1344px) {
    .binus-english-club {
        width: 22rem;
    }
}

.kotak-bnec {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.paragraf-bnec {
    text-align: end;
}

@media screen and (max-width: 1311px) {
    .kotak-bnec {
        justify-content: center;
    }

    .paragraf-bnec {
        position: relative;
        left: -220px;
        text-align: start;
    }

    .binus-english-club {
        position: relative;
        bottom: -120px;
    }
}

.judul {
    font-size: 54px;
}

@media screen and (max-width: 928px) {
    // .kotak-bnec{
    //     flex-direction: column;

    // }
    .paragraf-bnec {
        text-align: center;
        left: auto;
    }

    .binus-english-club {
        text-align: center;
        bottom: auto;
    }

    .kotak-bnec {
        text-align: center;
        margin-top: -40px;
    }
}

@media screen and (max-width: 330px) {
    .judul {
        font-size: 40px;
    }

    .paragraf-tanggal {
        font-size: 14px;
    }
}

// // Fold, Iphone 4
@media screen and (max-width: 358.98px) {

    //ini aku ubah
    .landing {
        width: 110%;
        min-height: 28vh !important;
        height: 28vh !important;
        background-size: 400px;
        background-position: center top;
        margin-top: 20px;
        margin-left: 0px;
        padding-top: 150px;
        background-image: none;
    }

    //copy aja utk jaga2
    .text-landing,
    .text-landing-orange,
    .text-landing-outline,
    .text-reasons-outline {
        font-size: 28px !important;
    }

    //ini aku tambah
    .btn-teal-landing {
        width: 200px;
        margin-left: auto;
        margin-right: auto;
    }

    .card {
        padding: 30px;
    }


    .img-hide {
        display: none;
    }

    .important-section {
        background-position: -9rem 26rem,
            center center !important;
    }

    .benefit-section {
        background-position: -15rem top, center center !important;
        padding: 6rem 0 0 0 !important;
    }

    .class-section {
        padding: 4rem 0 2rem 0 !important;
    }

    .about {
        .kotak-bnec {
            padding: 10px;
        }
    }
}


// Extra small devices (portrait phones, less than 576px)
@media (min-width: 359px) and (max-width: 575.98px) {

    //ini kuubah
    .landing {
        width: 100%;
        min-height: 35vh !important;
        height: 35vh !important;
        background-size: 460px;
        background-position: center top;
        margin-top: 1px;
        padding-top: 180px;
        margin-left: auto;
        margin-right: auto;
        background-image: none;

        .container {
            height: 50px !important;
        }
    }

    .text-landing {
        font-size: 28px;
    }

    .text-landing-orange {
        font-size: 34px;
    }

    .btn-teal-landing {
        width: 250px;
        margin-left: auto;
        margin-right: auto;
    }

    .text-reasons-outline {
        left: 39%;
        top: 1%;
        font-size: 36px;
    }

    .img-hide {
        display: none;
    }

    .owl-carousel {
        padding-left: 10px;
        padding-right: 10px;
    }

    .owl-carousel .nav-btn {
        width: 40px !important;
        top: 80px;
    }

    .owl-carousel .prev-slide {
        left: -12px !important;
    }

    .owl-carousel .next-slide {
        right: -12px !important;
    }

    .about-section {
        background-position: -35rem -1rem, 10rem 15rem, center center !important;
        padding: 9rem 0 0 0 !important;
    }

    .important-section {
        padding: 6rem 0 6rem 0;
        background-size: 450px, 100%;
        background-position: -9rem 24rem, center center;
    }

    .benefit-section {
        background-position: -13rem top, center center;
        padding: 9rem 0 0 0;
    }

    .class-section {
        padding: 9rem 0 0 0;
    }

    .quiz-section {
        padding: 0rem 0 3rem 0;
        background-size: 1000px, 100%;
    }

    .event-section {
        background-position: 80% 200px, center center;
        padding: 2rem 0;
    }

    .bom-section {
        padding: 2rem 0;
    }

    .talent-section {
        padding: 6rem 0;
        background-size: 500px, 300px, 100%;
        background-position: -5rem -3rem, 5rem 25rem, center center;
    }

    .achievement-section {
        padding: 1rem 0;
    }

    .video-section,
    .partnership-section {
        padding: 2rem 0;
    }

    .powered-section {
        padding: 2rem 0 4rem 0;
    }


}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

    //ini aku ubah
    .landing {
        min-height: 30vh !important;
        height: 30vh !important;
        border-radius: 0 0 80px 0;
        background-size: 900px;
        width: 100%;
        padding-top: 300px;
        padding-left: 1px;
        background-position: top center;
        background-image: none;

        .container {
            height: 25vh !important;
        }
    }

    //ini juga
    .text-landing,
    .text-landing-orange .text-landing-outline {
        font-size: 36px;
    }

    //ini aku ubah
    .btn-teal-landing {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }


    .img-hide {
        display: none;
    }

    .owl-carousel .nav-btn {
        width: 60px;
        top: 125px !important;
    }

    .about-section {
        background-position: -22rem -3rem, right bottom, center center !important;
        padding: 10rem 0 6rem 0;
    }

    .important-section {
        padding: 3rem 0 6rem 0;
        background-size: 450px, 100%;
        background-position: -10rem bottom, center center;
    }

    .benefit-section {
        background-position: -7rem top, center center;
        padding: 7rem 0 0 0;

        #reason-img {
            opacity: 0%;
        }
    }

    .text-reasons-outline {
        left: 39%;
    }

    .class-section {
        padding: 4rem 0;
    }

    .quiz-section {
        padding: 0rem 0 3rem 0;
        background-size: 1500px, 100%;
    }

    .event-section {
        background-position: 80% 200px, center center;
        padding: 2rem 0;
    }

    .bom-section {
        padding: 2rem 0;
    }

    .talent-section {
        padding: 7rem 0 3rem 0;
        background-size: 800px, 400px, 100%;
        background-position: -5rem -5rem, right bottom, center center;
    }

    .achievement-section {
        padding: 1rem 0;
    }

    .video-section,
    .partnership-section {
        padding: 2rem 0;
    }

    .powered-section {
        padding: 2rem 0 4rem 0;
    }

    .select-container #toefl-schedule {
        width: 100%;
        background: #FBEBD9;
        height: 1rem;

    }

}

@media (max-width:729px) {
    .card {
        padding-left: 0px;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

    //ini aku ubah
    .landing {
        min-height: 30vh !important;
        height: 70vh !important;
        border-radius: 0 0 80px 0;
        background-size: 800px;
        width: 100%;
        padding-left: 1px;
        background-position: top center;
        padding-top: 200px;
        background-image: none;

        h2 {
            font-size: 40px;
        }
    }

    //copy aja just in case
    .text-landing,
    .text-landing-orange {
        font-size: 42px;
    }

    //ini juga
    .btn-teal-landing {
        width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    .img-hide {
        display: none;
    }

    .about-section {
        background-position: -26rem -2rem, right bottom, center center;
        padding: 10rem 0 5rem 0;
    }

    .important-section {
        padding: 4rem 0 8rem 0;
        background-position: left 10rem, center center;

    }

    .benefit-section {
        padding: 8rem 0 2rem 0;
        background-size: 700px, 100%;
        background-position: -9rem top, center center;

        #reason-img {
            width: 150px !important;
        }
    }

    .quiz-section {
        padding: 2rem 0;
    }

    .event-section {
        padding: 4rem 0;
    }

    .bom-section {
        padding: 0 0 2rem 0;
    }

    .talent-section {
        padding: 6rem 0;
        background-size: 600px, 400px, 100%;
        background-position: -5rem -3rem, right 25rem, center center;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

    //ini aku ubah
    .landing {
        min-height: 500vh;
        height: 500vh;
        padding-top: 250px;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        // background-position: bottom center;
    }

    .text-landing,
    .text-landing-outline {
        font-size: 48px;
    }

    .btn-teal-landing {
        width: 500px;
    }

    // .about-section {
    //     background-position: -30rem -1rem, right 15rem, center center;
    // }
    .about-section {
        padding-top: 400px;
    }

    .bom-section {
        padding: 0 0 2rem 0;
    }

    .talent-section {
        background-position: -10rem -7rem, 35rem 25rem, center center;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1299.98px) {
    .text-landing-outline {
        left: 7%;
    }
}

// Styling Halaman TOEFL Details - Okky

.toefl-detail {
    font-family: 'Gill Sans MT';
    border: 1px solid #C6C6C6;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
    width: 100%;

}

.toefl-img {
    padding-left: 12rem;
}



.toefl-heading {

    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    font-style: normal;
}


.toefl-desc-container,
.toefl-goals-container {

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

}

.toefl-desc-container p,
.toefl-goals-container p {
    font-size: 20px;
    font-weight: 500;
}

.toefl-desc-container .subheading,
.toefl-goals-container .subheading {
    color: #1FBAA2;

}

.select-container #chooseSchedule,
.link-container .display-link {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
}

.link-container .zoom-link {
    font-size: 20px;
}

.select-container #toefl-schedule {
    width: 100%;
    background: #FBEBD9;
    height: 2.75rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-appearance: auto;
}

option {
    width: 100%;
}

.btn-container {
    display: block;
    margin-top: auto;
}

.btn-container .toefl-btn:disabled {
    font-size: 24px;
    text-decoration: none;
    background: #CBCBCB;
    color: #fff;
    border-radius: 15px;
    padding: 5px 120px;
    width: auto;
    text-align: center;

}

.btn-container .toefl-btn:disabled:hover {
    color: #FFFFFF;
    cursor: default;
}

.btn-container .toefl-btn {
    font-size: 24px;
    text-decoration: none;
    cursor: pointer;
    background: #1FBAA2;
    color: #fff;
    border-radius: 15px;
    padding: 5px 120px;
}.toefl-detail {
    font-family: 'Gill Sans MT';
    border: 1px solid #C6C6C6;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.25);
    width: 100%;

}

.toefl-img {
    padding-left: 12rem;
}



.toefl-heading {

    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    font-style: normal;
}


.toefl-desc-container,
.toefl-goals-container {

    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;

}

.toefl-desc-container p,
.toefl-goals-container p {
    font-size: 20px;
    font-weight: 500;
}

.toefl-desc-container .subheading,
.toefl-goals-container .subheading {
    color: #1FBAA2;

}

.select-container #chooseSchedule,
.link-container .display-link {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
}

.link-container .zoom-link {
    font-size: 20px;
}

.select-container #toefl-schedule {
    width: 100%;
    background: #FBEBD9;
    height: 2.75rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    -webkit-appearance: auto;
}

option {
    width: 100%;
}

.btn-container {
    display: block;
    margin-top: auto;
}

.btn-container .toefl-btn:disabled {
    font-size: 24px;
    text-decoration: none;
    background: #CBCBCB;
    color: #fff;
    border-radius: 15px;
    padding: 5px 120px;
    width: auto;
    text-align: center;

}

.btn-container .toefl-btn:disabled:hover {
    color: #FFFFFF;
    cursor: default;
}

.btn-container .toefl-btn {
    font-size: 24px;
    text-decoration: none;
    cursor: pointer;
    background: #1FBAA2;
    color: #fff;
    border-radius: 15px;
    padding: 5px 120px;
    width: auto;
    text-align: center;
}

.btn-container .toefl-btn:hover {
    color: #000000;
}


.modal-content {
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;

}

.confirmation-popup {
    background: #F8F9FA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
}


.confirmation-popup .confirmation-text {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
}

.cancel-btn {
    background: #3A3A3C;
    color: #fff;

}

.cancel-btn:hover {
    color: #1FBAA2;
}

.confirm-btn {
    background: #1FBAA2;
    color: #FFFFFF;
}

.confirm-btn,
.cancel-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    border-radius: 12px;

}

.success-popup {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
}

.success-popup .success-text {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    width: 75%;
    margin-left: 12.5%;
}

.form-control:focus {
    background-color: #FBEBD9;
}

.success-popup .close-btn {
    background: #1FBAA2;
    color: #fff;
    border-radius: 6px;
    padding: 0.2rem 9rem;
    font-size: 20px;
    text-align: center;
}

.success-popup .close-btn:hover {
    color: #000000;
}


.edit-modal h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 42px;
}

.edit-modal label {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;}

.edit-modal select,
.edit-modal option {
    background: #FBEBD9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20.6452px;
    -webkit-appearance: auto;
}

.edit-modal textarea {
    background: #FBEBD9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    height: 129px;
    resize: none;
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {


    .toefl-img {
        padding-left: 0;
        padding-right: 5rem;
    }

    .toefl-detail {
        width: 90%;

    }

    .toefl-heading {

        font-size: 28px;
    }

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

    .toefl-detail {
        display: block;
    }

    .toefl-img {
        padding-left: 0;

    }

    .btn-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;

    }

    .content {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

    


}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
    .btn-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .content {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

    .btn-container .toefl-btn:disabled {
        padding: 5px 25px;
    }
    
    .btn-container .toefl-btn {
        padding: 5px 25px;
    }
    
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .toefl-detail {
        display: block !important;
    }

    .toefl-img {
        padding-left: 0;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    .content {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

    .btn-container .toefl-btn:disabled {
        padding: 5px 20px;
    }
    
    .btn-container .toefl-btn {
        padding: 5px 20px;
    }

}

.alert {
    justify-items: center !important;
    img{
        justify-items: center !important;
        margin-left: auto;
        margin-right: auto;
        width: 400px;
    }
}

@media screen and (max-width: 491px) {
    .alert img{
        width: 100%;
    }
}

.button-edit{
    justify-items: center !important;
    margin-left:auto !important; 
    margin-right:auto !important;
    a{
        justify-items: center !important;
        margin-left:auto !important; 
        margin-right:auto !important;
    }
    .thanks{
        justify-items: center !important;
        margin-left:auto !important; 
        margin-right:auto !important;       
    }
}